import Phaser, { Time } from 'phaser';
import Question from '../componenents/Question';
import LifeLine from '../componenents/Lifeline';
import Timer  from '../componenents/Timer';
import UI from '../componenents/ui';
import Progress from "../componenents/progress";

import EventsManager from "../utilities/EventsManager"
import GameServer from "../utilities/Gameserver"

export default class MainScene extends Phaser.Scene
{

	leftLight !: any;
	rightLight !: any;
	chairLeft !: any;
	chairRight !: any;
	bg !: any;
	progressBarArr !:  any;
	profileImg !: any;
	//lifeLine buttons
	fiftyFifty !: any;
	flipTheQuestion !: any;
	askSalesExpert !: any;

	questionIndex !: number
	gameServer !: any;

	//optionsArr
	progressBarYPos !: Array<any>;
	
	questionPanel !: Phaser.GameObjects.Image;

	progressBar !: Phaser.GameObjects.Image;

	currentLevel !: number;

	uiClass !: any;

	QuestionObj !: any;
	timerObj !:any;
	lifeLineObj !: any;
	playText !: any;
	public emitter : any;

	logo !: any;

	data !: any;

	rightChoice !: any;

	programId !: any;

	tokenId !: any;


	constructor()
	{
		super('MainScene')
		this.progressBarArr = [];
		this.currentLevel = 0;
		this.questionIndex = 0;

		this.emitter = EventsManager.getInstance();
		this.gameServer = GameServer.getInstance();

	}

	create() 
	{
	    // const url = window.location.href;
	    // const params = (new URL(url)).searchParams;
	    // const tokenId = params.get('token');
		// let taskId = params.get("taskid");
		// let program_id = params.get('programid');
		// let loginNumber = params.get("mobileno");
		// let userName = params.get("displayname")

		// this.programId = program_id;
		// this.tokenId = tokenId;

		// console.log("loginNumberANdUnsername",loginNumber,userName)

		// this.gameServer.createPlayer(loginNumber,userName);

		// this.gameServer.getRewardDetails(tokenId,program_id,taskId);

		this.data = this.cache.json.get('questions');
		console.log("this.data",this.data)

		this.bg = this.add.image(this.cameras.main.width * .53 ,this.cameras.main.height * .55,"portBg").setScale(1.2);
		this.animateSpotLight();
			
	}

	animateSpotLight(){
		this.leftLight = this.add.image(this.cameras.main.width * .7,-this.cameras.main.height *.08,"spotLight").setAngle(20).setOrigin(0.5,0);
		this.rightLight = this.add.image(this.cameras.main.width * .29,-this.cameras.main.height * .08,"spotLight").setAngle(-20).setOrigin(0.5,0);
		var _self = this;
		var dialogBGtween = this.tweens.add({
			targets: _self.leftLight,
			angle: -60,
			duration: 400,
			yoyo: true,
			repeat: 2,
			ease: "Linear",
			onComplete: function () {
			}
		  });
		  var dialogBGtween = this.tweens.add({
			targets: _self.rightLight,
			angle: 60,
			duration: 400,
			yoyo: true,
			repeat: 2,
			ease: "Linear",
			onComplete: function () {
			 _self.gameStartWindow();
			}
		  });

	}

	gameStartWindow() {
		///var productLogo = this.add.image(this.cameras.main.width * .55,this.cameras.main.height * .15,"cadburyLogo").setScale(0.35);
		 this.chairLeft = this.add.image(this.cameras.main.width * .3,this.cameras.main.height * .72,"chair").setScale(.3);

		 this.chairRight = this.add.image(this.cameras.main.width * .7,this.cameras.main.height * .72,"chair").setScale(.3);
		 this.chairRight.flipX = true;

		 this.logo = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .15,"logo").setScale(1.5);
		 this.addPlayButton();

		 this.addScreenIcon();

		 this.uiClass = new UI(this,0,0);	
	}

	addScreenIcon() {
		
	  var screen = this.add.image(this.cameras.main.width * .52,this.cameras.main.height * .72,"screen").setScale(.35,.45);

	  var questionAnim  = this.add.sprite(this.cameras.main.width * .5,this.cameras.main.height * .68, "questionsSheet").setScale(.25);

	  this.anims.create({
		key: 'questionIdle',
		frames: [
			{ key: 'questionsSheet', frame: 'Question-mark-animation-4@4x.png' },
			{ key: 'questionsSheet', frame: 'Question-mark-animation-5@4x.png' },
			{ key: 'questionsSheet', frame: 'Question-mark-animation-2@4x.png' },
			{ key: 'questionsSheet', frame: 'Question-mark-animation-6@4x.png' },
			{ key: 'questionsSheet', frame: 'Question-mark-animation-3@4x.png' }
		],
		frameRate: 7,
		repeat: -1
	});

	questionAnim.play("questionIdle");	

	}

	addPlayButton() {
		// this.gameServer.getPlayerData();
		var _self = this;
		var buttonImg = this.add.sprite(this.cameras.main.width * .5,this.cameras.main.height * .5, "playButtonAnimation").setScale(.6).setInteractive().on('pointerdown', () => {
			_self.toggleFullScreen();
			buttonImg.destroy();
			_self.playText.destroy();
			_self.leftLight.destroy();
			_self.rightLight.destroy();
			_self.chairLeft.destroy();
			_self.chairRight.destroy();
			_self.logo.destroy();
			_self.bg.destroy();
			_self.uiClass.destroy();
			new Progress(this,0,0).startGame();
			_self.timerObj = new Timer(this,0,0);
			_self.QuestionObj = new Question(this,0,0);
			_self.lifeLineObj = new LifeLine(this,0,0)
		});
	    this.playText = this.add.text(this.cameras.main.width * .5, this.cameras.main.height * .5, "PLAY").setFontFamily("RussoOne").setColor("#FFFFFF").setFontSize(70).setOrigin(0.5);

		this.anims.create({
			key: 'idle',
			frames: [
				{ key: 'playButtonAnimation', frame: 'Play-button1.png' },
				{ key: 'playButtonAnimation', frame: 'Play-button2.png' },
				{ key: 'playButtonAnimation', frame: 'Play-button3.png' },
				{ key: 'playButtonAnimation', frame: 'Play-button4.png' }
			],
			frameRate: 7,
			repeat: -1
		});

		buttonImg.play("idle");	
	}

	toggleFullScreen() {
		var isAndroid = /Android/i.test(navigator.userAgent);
		if(isAndroid){
		if(!document.fullscreenElement !== undefined){
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		}
	}
	}
    }

	//Fix the bug
	addSelectedAnimation(xPos,yPos){
		var _self = this;
		var buttonImg = this.add.sprite(xPos,yPos, "buttonIdle").setScale(.6).setInteractive().on('pointerdown', () => {
			
		});
		this.anims.create({
		  key:"buttonIdle1",
		  frames: this.anims.generateFrameNumbers('buttonIdle', { start: 0, end: 3 }),
		  frameRate: 7,
		  repeat: -1
		});
		buttonImg.play("buttonIdle1");

	}

}




