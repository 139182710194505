import Phaser from 'phaser';
import axios from 'axios';

import { gameServerLink } from "../Types/QuizDataTypes"

var instance : any = null;
var userToken : any = null;
var current_level : any = null;

var lifelineData : any = null;
var leaderBoardData : any = null;

export default class GameServer{
    constructor() {
    }

    createPlayer(name, number) {
        console.log("createPlayer");
        const axiosConfig = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;'
            }
          }
         
        let data = {
                     "mobile_no": number,
                     "name": name
            }
            

        var _self = this;
        axios.post(`${gameServerLink}login`,data).then(function (response) {     
                    userToken = response.data.data.access_token
                    console.log("userToken",userToken)
                    _self.assignUserToken(userToken);
            }).catch(function (error) {
                    console.log("response",error)
            });
    }

    getRewardDetails(tokenId,programId,taskId) {
        console.log("Beared_token", tokenId);

       const appServerURL = "https://api.app.bigcitytrade2.bigcityvoucher.co.in/v1/task/get-game-details"

        let axiosConfig  = {
            headers: {
                'Authorization': tokenId,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json'
            }
        }

        console.log("GetRewardDetails",programId,taskId);

        var formData = new FormData();
        formData.append('program_id', programId);
        formData.append('task_id', taskId);

        axios.get("https://api.app.bigcitytrade2.bigcityvoucher.co.in/v1/task/get-game-details", axiosConfig).then(function (response) {              
            console.log("Response",response);
            // GAME_TYPE = response.game_type;
            // GAME_SCORE = response.points;
          }).catch(function (error) {
              console.log("error",error)
          });
    }

    assignUserToken(token) {
        userToken = token;
        this.getPlayerData();
    }

    sendScoreToApp(currentScore,tokenId,programId,taskid) {
        console.log("sendscoredata")
        
        var bearerToken : any  = '8f98b1af8f2616c4258399ce62b641f0';
        let axiosConfig = {
            headers: {
                'Authorization': tokenId,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json'
            }
        }

        var formData = new FormData();
        formData.append('program_id', programId);
        formData.append('task_id',taskid);
        formData.append('points',currentScore);

        console.log(formData)
      
        axios.post("https://api.app.bigcitytrade2.bigcityvoucher.co.in/v1/task/credit-game-point", formData,axiosConfig).then(function (response) {              
          console.log("Response",response);
        }).catch(function (error) {
            console.log("error",error)
        });
    }

    getPlayerData() {
        console.warn("imbeingcalledfrom")
        const axiosConfig = {
            headers: {
                'Authorization':'Bearer ' + userToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;charset=UTF-8',
            }
          }


        axios.get(`${gameServerLink}get-userdata`,axiosConfig).then(function (response) {     
            current_level = response.data.data.current_level;
            console.log("getPlayerData",response)
            }).catch(function (error) {
                console.log("response",error)
            });

    }

    returnCurrentLevel() {
        return current_level;
    }

    updateScore(currentscore,currentlevel) {
        const axiosConfig = {
            headers: {
                'Authorization': 'Bearer '+ userToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;charset=UTF-8',
            }
          }

          let data = {
            score: currentscore,
            level: currentlevel
            }
        console.log("currentlevelBefore",currentlevel)

        var _self = this;

        axios.post(`${gameServerLink}update-userscore`,data,axiosConfig).then(function (response) {     
               console.log("updateScore",response)       
               _self.getPlayerData(); 
            }).catch(function (error) {
                    console.log("response",error)
        });
    }

    updateLifeline(lifelineId,level) {
        const axiosConfig = {
            headers: {
                'Authorization': 'Bearer '+ userToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;charset=UTF-8',
            }
          }


          let data = {
            game_life_line_id:lifelineId,
            level: level
            }

            console.log("UPDATELIFELINE",level)


        axios.post(`${gameServerLink}update-lifeline`,data,axiosConfig).then(function (response) {     
               console.log("update-lifeline",response)        
            }).catch(function (error) {
                    console.log("response",error)
            });
    }

    getLifeLineData() {
        const axiosConfig = {
            headers: {
                'Authorization': 'Bearer '+ userToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;charset=UTF-8',
            }
          }
    
        axios.get(`${gameServerLink}get-lifeline`,axiosConfig).then(function (response) {     
               lifelineData = response;    
               console.log("lifeLineData",lifelineData)     
            }).catch(function (error) {
                    console.log("response",error)
            });
    }

    return5050LifeLineStatus() {
        return lifelineData.data.data[0].status;
    }

    returnSwapLifeLineStatus() {
       return lifelineData.data.data[1].status;
    }

    returnAskLifeLineStatus() {
       return lifelineData.data.data[2].status;
    }

    getLeaderBoardData() {
        const axiosConfig = {
            headers: {
                'Authorization': 'Bearer '+ userToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json;charset=UTF-8',
            }
          }

            let data = {
            }
    
        axios.get(`${gameServerLink}get-leaderboard`,axiosConfig).then(function (response) {     
               console.log("leadeBoardData",response) 
               leaderBoardData =  response.data.data;
            }).catch(function (error) {
                    console.log("response",error)
            });
    }

    returnLeaderBoardData() {
        console.log("leaderBoardData",leaderBoardData)
        return leaderBoardData;
    }


    static getInstance() {
        if (instance == null) {
            instance = new GameServer();
        }
        return instance;
    }
}


