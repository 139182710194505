
export default  class UI extends Phaser.GameObjects.Container {

    howToPlayBtn !: Phaser.GameObjects.Image;
    soundsBtn !: Phaser.GameObjects.Image;
    leaderBoardBtn !: Phaser.GameObjects.Image;

    homeIcon !: Phaser.GameObjects.Image;
    soundsIcon !: Phaser.GameObjects.Image;
    leaderBoardIcon !: Phaser.GameObjects.Image;

    scene: any;

    howToplayBtnGrp !: any;

    leaderBoardText !: any;

    leaderBoardData !: any;

    constructor(scene,x,y) {
        super(scene,x,y)
        this.scene = scene;
        scene.add.existing(this);
        this.howToplayBtnGrp = this.scene.add.group();
        this.leaderBoardText = this.scene.add.group();
        this.addHUD();

    }

    addHUD() {
        this.leaderBoardData = this.scene.gameServer.getLeaderBoardData();

        var _self = this;
        this.howToPlayBtn = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .93,"buttonIcon").setInteractive().on('pointerdown', () => {
            _self.triggerHowToPlayPopup();
        });

		this.soundsBtn = this.scene.add.image(this.scene.cameras.main.width * .8,this.scene.cameras.main.height * .93,"buttonIcon").setInteractive().on('pointerdown', () => {
            _self.toggleSound();
        });

		this.leaderBoardBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .93,"buttonIcon").setInteractive().on('pointerdown', () => {
            _self.loadLeaderBoardContent();
        });

		//AddIcon
		this.homeIcon =  this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .93,"homeIcon");
		this.soundsIcon =  this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .93,"leaderBoardIcon")
		this.leaderBoardIcon =   this.scene.add.image(this.scene.cameras.main.width * .8,this.scene.cameras.main.height * .93,"volumeOffIcon")
    }


    triggerHowToPlayPopup() {
        var _self = this;
        var plainBg = this.scene.add.image(this.scene.cameras.main.width * 2.53 ,this.scene.cameras.main.height * .5,"portraitBg").setScale(1.2);
        var dialogBGtween =  this.scene.tweens.add({
            targets: plainBg,
            x: this.scene.cameras.main.width * .52,
            duration: 500,
            ease: "Linear",
            onComplete: function () {
                var arrowBtn = _self.scene.add.image(_self.scene.cameras.main.width * .15,_self.scene.cameras.main.height * .05,"arrowBtn").setScale(0.15).setInteractive().on("pointerdown", () => {
                    _self.howToplayBtnGrp.clear(true);
                    arrowBtn.destroy();
                    var dialogBGtween =  _self.scene.tweens.add({
                        targets: plainBg,
                        x: _self.scene.cameras.main.width * 2.52,
                        duration: 500,
                        ease: "Linear",
                        onComplete: function () {
                            plainBg.destroy();
                        }
                    });
                })
                _self.addHowToPlayTexts();
            }
        });
    }

    addHowToPlayTexts() {
        var title =   this.scene.add.text(
            this.scene.cameras.main.width * .52, 
            this.scene.cameras.main.height * .12, 
            "HOW TO PLAY?")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(70)
            .setOrigin(0.5)
            .setWordWrapWidth(900)

      this.howToplayBtnGrp.add(title);

        var pointOne = this.scene.add.text(
            this.scene.cameras.main.width * .51, 
            this.scene.cameras.main.height * .2, 
            "1. There are 10 levels in the game consisting of 3 questions each.")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(50)
            .setOrigin(0.5)
            .setWordWrapWidth(900)

       this.howToplayBtnGrp.add(pointOne);


        var pointTwo = this.scene.add.text(
                this.scene.cameras.main.width * .52, 
                this.scene.cameras.main.height * .3, 
                "2. To answer each question in a level, the contestant gets up to 20 seconds, 15 seconds and 10 seconds respectively.")
                .setFontFamily("RussoOne")
                .setColor("#FFFFFF")
                .setFontSize(50)
                .setOrigin(0.5)
                .setWordWrapWidth(900)
        
        this.howToplayBtnGrp.add(pointTwo);

            
        var pointThree = this.scene.add.text(
                 this.scene.cameras.main.width * .52, 
                this.scene.cameras.main.height * .4, 
                "3. The game consists of 3 lifelines")
                .setFontFamily("RussoOne")
                .setColor("#FFFFFF")
                .setFontSize(50)
                .setOrigin(0.5)
                .setWordWrapWidth(900)
        
        this.howToplayBtnGrp.add(pointThree);


        var fiftyFiftyBtn = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .45, "buttonIcon").setScale(.8);
        this.howToplayBtnGrp.add(fiftyFiftyBtn);


		var fiftyIcon = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .45,"fiftyIcon").setScale(.5);
        this.howToplayBtnGrp.add(fiftyIcon);


        var fiftyFiftyDesc = this.scene.add.text(
            this.scene.cameras.main.width * .57, 
           this.scene.cameras.main.height * .45, 
           "50:50 - Omit two wrong choices in the question")
           .setFontFamily("RussoOne")
           .setColor("#FFFFFF")
           .setFontSize(45)
           .setOrigin(0.5)
           .setWordWrapWidth(700)
           this.howToplayBtnGrp.add(fiftyFiftyDesc);


           var SalespertBtn = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .52, "buttonIcon").setScale(.8)
           this.howToplayBtnGrp.add(SalespertBtn);


           var salesPertIcon = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .52,"asksalespert").setScale(.5);
           this.howToplayBtnGrp.add(salesPertIcon);


           var SalespertDesc = this.scene.add.text(
               this.scene.cameras.main.width * .61, 
              this.scene.cameras.main.height * .52, 
              "Ask the Salespert - Avail a written context on what the answer should be")
              .setFontFamily("RussoOne")
              .setColor("#FFFFFF")
              .setFontSize(45)
              .setOrigin(0.5)
              .setWordWrapWidth(700)
              this.howToplayBtnGrp.add(SalespertDesc);


          var flipBtn = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .61, "buttonIcon").setScale(.8)
          this.howToplayBtnGrp.add(flipBtn);


		  var swapIcon = this.scene.add.image(this.scene.cameras.main.width * .2,this.scene.cameras.main.height * .61,"swapIcon").setScale(.5);
          this.howToplayBtnGrp.add(swapIcon);



          var flipDesc = this.scene.add.text(
                  this.scene.cameras.main.width * .61, 
                 this.scene.cameras.main.height * .61, 
                 "Flip the Question - Get an alternative question instead of the ongoing one")
                 .setFontFamily("RussoOne")
                 .setColor("#FFFFFF")
                 .setFontSize(45)
                 .setOrigin(0.5)
                 .setWordWrapWidth(700)
                 this.howToplayBtnGrp.add(flipDesc);
 
        
          var pointFour = this.scene.add.text(
                    this.scene.cameras.main.width * .52, 
                   this.scene.cameras.main.height * .75, 
                   "4. Points earned can be redeemed for rewards and experiences in the catalog and also to buy lifelines for advancing within the game")
                   .setFontFamily("RussoOne")
                   .setColor("#FFFFFF")
                   .setFontSize(50)
                   .setOrigin(0.5)
                   .setWordWrapWidth(900)
        this.howToplayBtnGrp.add(pointFour);

            
                   var pointFive = this.scene.add.text(
                    this.scene.cameras.main.width * .51, 
                   this.scene.cameras.main.height * .85, 
                   "5. A virtual host will conduct the game ")
                   .setFontFamily("RussoOne")
                   .setColor("#FFFFFF")
                   .setFontSize(50)
                   .setOrigin(0.5)
                   .setWordWrapWidth(900)
        this.howToplayBtnGrp.add(pointFive);


    }


    toggleSound() {

    }

    loadLeaderBoardContent() {
        var _self = this;
        var plainBg = this.scene.add.image(this.scene.cameras.main.width * 2.53 ,this.scene.cameras.main.height * .5,"portraitBg").setScale(1.2);
       
        var dialogBGtween =  this.scene.tweens.add({
            targets: plainBg,
            x: this.scene.cameras.main.width * .52,
            duration: 500,
            ease: "Linear",
            onComplete: function () {
                _self.addLeaderBoardData();
                var arrowBtn = _self.scene.add.image(_self.scene.cameras.main.width * .15,_self.scene.cameras.main.height * .05,"arrowBtn").setScale(0.15).setInteractive().on("pointerdown", () => {
                    arrowBtn.destroy();
                    _self.leaderBoardText.clear(true);
                    var dialogBGtween =  _self.scene.tweens.add({
                        targets: plainBg,
                        x: _self.scene.cameras.main.width * 2.52,
                        duration: 500,
                        ease: "Linear",
                        onComplete: function () {
                            plainBg.destroy();
                        }
                    });
                })
            }
        });

    }

    addLeaderBoardData() {


        this.leaderBoardData = this.scene.gameServer.returnLeaderBoardData();
        console.log("leaderBoardData",this.leaderBoardData)

        var rankTitle = this.scene.add.text(
            this.scene.cameras.main.width * .2, 
            this.scene.cameras.main.height * .15, 
            "RANK")
            .setFontFamily("RussoOne")
            .setColor("#ADD8E6")
            .setFontSize(50)
            .setOrigin(0.5)
            .setDepth(3006)
            .setWordWrapWidth(600).setAlpha(0);
        
        this.leaderBoardText.add(rankTitle);

        var nameTitle = this.scene.add.text(
            this.scene.cameras.main.width * .52, 
            this.scene.cameras.main.height * .15, 
            "NAME")
            .setFontFamily("RussoOne")
            .setColor("#ADD8E6")
            .setFontSize(50)
            .setOrigin(0.5)
            .setDepth(3006)
            .setWordWrapWidth(600).setAlpha(0);
        
        this.leaderBoardText.add(nameTitle);


        var scoreTitle = this.scene.add.text(
            this.scene.cameras.main.width * .85, 
            this.scene.cameras.main.height * .15, 
            "SCORE")
            .setFontFamily("RussoOne")
            .setColor("#ADD8E6")
            .setFontSize(50)
            .setOrigin(0.5)
            .setDepth(3006)
            .setWordWrapWidth(600).setAlpha(0);


        var leaderBoardTitle = this.scene.add.text(
             this.scene.cameras.main.width * .52, 
             this.scene.cameras.main.height * .05, 
             "LEADERBOARD")
              .setFontFamily("RussoOne")
              .setColor("#ADD8E6")
              .setFontSize(80)
              .setOrigin(0.5)
              .setDepth(3006)
              .setWordWrapWidth(600);
        
        this.leaderBoardText.add(leaderBoardTitle);


        var y_Threshold = .15;


        var layOut = this.scene.add.image(this.scene.cameras.main.width * .51,this.scene.cameras.main.height * .55, "emptyBg").setScale(0.8,1);

        this.leaderBoardText.add(layOut);


        
        for(let i = 0;i<this.leaderBoardData.length;i++) {

            var namePanel = this.scene.add.image(this.scene.cameras.main.width * .52 ,this.scene.cameras.main.height * y_Threshold,"nameLabel").setScale(1.2,0.7);

            this.leaderBoardText.add(namePanel);

            var playerRank = this.scene.add.text(
                this.scene.cameras.main.width * .24, 
                this.scene.cameras.main.height * y_Threshold, 
                this.leaderBoardData[i].rank)
                .setFontFamily("RussoOne")
                .setColor("#ADD8E6")
                .setFontSize(50)
                .setOrigin(0.5)
                .setDepth(3006)
                .setWordWrapWidth(600);
            
            this.leaderBoardText.add(playerRank);
    
            var playerName = this.scene.add.text(
                this.scene.cameras.main.width * .52, 
                this.scene.cameras.main.height * y_Threshold, 
                this.leaderBoardData[i].name)
                .setFontFamily("RussoOne")
                .setColor("#ADD8E6")
                .setFontSize(50)
                .setOrigin(0.5)
                .setDepth(3006)
                .setWordWrapWidth(600);

            this.leaderBoardText.add(playerName);

    
            var playerScore = this.scene.add.text(
                this.scene.cameras.main.width * .79, 
                this.scene.cameras.main.height * y_Threshold, 
                this.leaderBoardData[i].total_score)
                .setFontFamily("RussoOne")
                .setColor("#ADD8E6")
                .setFontSize(50)
                .setOrigin(0.5)
                .setDepth(3006)
                .setWordWrapWidth(600);
            
            this.leaderBoardText.add(playerScore);

            
            y_Threshold += .08;

        }
        
            
            

    }

    
}

