import  config  from "../main"

export default class LoadScene extends Phaser.Scene
{

    bg !: Phaser.GameObjects.Image;
    title !: Phaser.GameObjects.Image;
    salesFigure1 !: Phaser.GameObjects.Image;
    salesFigure2 !: Phaser.GameObjects.Image;
    loadComplete !: any;
	sponsor !: Phaser.GameObjects.Image;
    value !: any;
	logo !: any;
	promoter !: Phaser.GameObjects.Image;


	constructor()
	{
        super({
			key: 'loader',
			pack: {
				files: [
					{
						type: 'image',
						key: "bg",
						url: "assets/Empty-bg.png"
					},
					{
						type: 'image',
						key: "logo",
						url: "assets/logo.png"
					}
				]
			}
		});
	}

    preload() {	
	 	this.bg = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .5,"bg").setScale(1.5);	
		this.loadProgressBar();
		this.logo = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .2,"logo").setScale(1.5);
		this.load.image("portBg", "assets/portraitBG.png");
		this.load.image("chair","assets/chair.png");
		this.load.image("spotLight","assets/spotlight.png");
		this.load.image("cadburyLogo","assets/cadbury.png");
		this.load.image("glowAnim","assets/glowAnim.png");
		this.load.image("progressButton","assets/progressButton.png");
		this.load.spritesheet("buttonIdle", "assets/newplaybutton.png", { frameWidth: 798, frameHeight: 172, endFrame: 4 });

		this.load.atlas("playButtonAnimation",'assets/updatedPlayButton.png','assets/updatedPlayButton.json')

		this.load.atlas("coinEffect", "assets/coinspinAnimation.png", "assets/coinspinAnimation.json");

		this.load.image("silkAd", "assets/silkAd.png")

		this.load.image("emptyBg","assets/Empty-bg.png");

		this.load.image("profileImg","assets/profile.png");

		this.load.image("timerImg","assets/timer.png");

		this.load.image("godray","assets/godray.png");

		this.load.json('questions', "assets/questions.json");

		this.load.image("coin","assets/coin.png");


		this.load.image("nameLabel","assets/finalLevel.png");

		//lifelineIcons
		this.load.image("lifelines5050","assets/lifeline50.png");

		this.load.image("businessman","assets/businessman.png");
		
		this.load.image("greyScale","assets/greyScale.jpg");

		this.load.image("close","assets/close.png");

		this.load.image("alertanimation01","assets/alertanimation01.png");

		this.load.spritesheet("alertAnimation", "assets/alertAnimation.png", {frameWidth: 1082, frameHeight:1922, endFrame: 3});

		this.load.spritesheet("coinAnimation","assets/coinAnimation.png",{frameWidth:384, frameHeight:384,endFrame:20})

		//ui Icons
		this.load.image("homeicon","assets/questionIcon.png");

		this.load.image("portraitBg", "assets/portBg.png")

		this.load.image("buttonIcon","assets/button.png");

		this.load.image("asksalespert","assets/asksalespert.png");


		this.load.image("volumeOffIcon","assets/volumeoff.png");
		this.load.image("volumeOnIcon","assets/volumeon.png");
		this.load.image("leaderBoardIcon","assets/leaderboard.png");
		this.load.image("homeIcon","assets/questionIcon.png");

		this.load.image("profile","assets/profile.png");
		this.load.image("swapIcon","assets/refresh.png");
		this.load.image("fiftyIcon","assets/50-50.png");

		this.load.image("betbutton","assets/betbutton.png");

		this.load.image("screen","assets/screen.png");
		this.load.atlas("questionsSheet",'assets/questionsSHeet.png','assets/questionsSHeet.json')

		this.load.image("questionPanel","assets/panel.png");
		this.load.image("popupPanel","assets/popup_panel.png");

		this.load.image("betselected","assets/betselected.png");

		this.load.image("rightAnswer","assets/rightanswer.png");

		this.load.image("wrongAnswer","assets/wrongAnswer.png");

		this.load.image("logo","assets/logo.png");

		this.load.image("coin","assets/coin.png");

		this.load.image("arrowBtn","assets/backArrow.png");

		this.loadAudios();

		// this.load.bitmapFont('normalFont', 'assets/clarendon.png', 'assets/clarendon.xml');


    }

    loadProgressBar() {
     
		let progressBar = this.add.graphics();
		let progressBox = this.add.graphics();
		progressBox.fillStyle(0x222222, 0.1);
		progressBox.fillRect(
			config.scale.width / 2 - 150,
			config.scale.height / 2 - 30,
			320,
			50
		)

		this.load.on("progress", function (value) {
			progressBar.clear();
			progressBar.fillStyle(0x48beff, 1);
			progressBar.fillRect(
				config.scale.width / 2 - 140,
				config.scale.height / 2 - 20,
				300 * value,
				30
			)
			//percentText.setText(parseInt(value * 100) + " %");
		});

		this.load.on("fileprogress", function (file) { });
		this.loadComplete = false;
		var _self = this;
		this.load.on("complete", function () {
			 progressBar.destroy();
			 progressBox.destroy();
			 _self.logo.destroy();
			 _self.bg.destroy();
		});
	}

	loadAudios(){
		this.load.audio('commercialBreakSFX', 'assets/soundfiles/commerical-break.ogg');
		this.load.audio('correctAnswerSFX', 'assets/soundfiles/correct-answer.ogg');
		this.load.audio('introMusicSFX', 'assets/lets-play.ogg');
		this.load.audio('phoneafriendSFX', 'assets/soundfiles/phone-a-friend.ogg');
		this.load.audio('wrongAnswerSFX', 'assets/soundfiles/wrong-answer.ogg');
		this.load.audio("gameplaySfx","assets/soundfiles/gameplaySfx.ogg")
	}

    create() {
        this.scene.start("MainScene");
    }


}


