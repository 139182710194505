import EventsManager from "../utilities/EventsManager"


export default  class Progress extends Phaser.GameObjects.Container {

    emptyBg !: Phaser.GameObjects.Image;
    cadburyIcon !: Phaser.GameObjects.Sprite;

    profileImg !: Phaser.GameObjects.Image;
    progressBar !: Phaser.GameObjects.Image;

	scene : any;

    progressBarArr !: Array<any>

	emitter : any;

	progressText !: Array<any>;

	progressTextObj !: Array<any>;

	progressBarLvl !: any;

	yPosProfileImg !: any;

	yPosition !: Array<any>;

	introSound !: any;

	current_level !: any;

	coins !: any;

    scoreText !: any;


    constructor(scene,x,y) {
        super(scene,x,y);
		this.scene = scene;
		this.progressBarArr = [];
		this.progressTextObj = [];
		this.yPosition = [];
		this.yPosProfileImg = null;
		this.progressBarLvl = this.scene.add.group();
		this.progressText = ["LEVEL 1","LEVEL 2","LEVEL 3","LEVEL 4","LEVEL 5","LEVEL 6"];
		this.emitter = EventsManager.getInstance();

		this.emitter.on("retry",this.addProgressScreen.bind(this))	
		this.emitter.on("loadNextLevel",this.loadNextLevel.bind(this))
		this.emitter.on("reload",this.reloadLevel.bind(this));
    }

	startGame() {
		this.emptyBg = this.scene.add.image(this.scene.cameras.main.width * .53 ,this.scene.cameras.main.height * .5,"portraitBg").setScale(1.2);
		this.addProgressScreen();
	}


	addPoints() {

		var coins = this.scene.add.sprite(this.scene.cameras.main.width * .15, this.scene.cameras.main.height * .03, 'coin').setScale(.3).setDepth(99);

		this.coins = coins;

		var scoreString = (this.current_level - 1) * 50 + "";

		this.scoreText =  this.scene.add.text(this.scene.cameras.main.width * .29, this.scene.cameras.main.height * .03,scoreString).setFontFamily("RussoOne").setColor("#FFFFFF").setFontSize(70).setOrigin(0.5).setDepth(2000);

	}

    addProgressScreen() {

		this.cadburyIcon = this.scene.add.image(this.scene.cameras.main.width * .49,this.scene.cameras.main.height * .15, "logo").setScale(2);

		// var electedBetAnimation = this.scene.tweens.add({
		// 	targets: this.cadburyIcon,
		// 	scale: 1.7,
		// 	duration: 1000,
		// 	yoyo: true,
		// 	repeat: -1,
		// 	ease: "Linear",
		// 	onComplete: function () { 
		
		// 	}
		//   });


		this.addProgressBar();
		this.introSound = this.scene.sound.add("commercialBreakSFX", { loop: false });
		this.introSound.play();

    }


	reloadLevel() {
		this.cadburyIcon = this.scene.add.image(this.scene.cameras.main.width * .49,this.scene.cameras.main.height * .21, "logo").setScale(2);

		var electedBetAnimation = this.scene.tweens.add({
			targets: this.cadburyIcon,
			scale: 1.7,
			duration: 1000,
			yoyo: true,
			repeat: -1,
			ease: "Linear",
			onComplete: function () { 
		
			}
		  });


		this.addProgressBar();

		this.introSound = this.scene.sound.add("commercialBreakSFX", { loop: false });
		this.introSound.play();

	}

	loadNextLevel() {
		this.cadburyIcon = this.scene.add.image(this.scene.cameras.main.width * .49,this.scene.cameras.main.height * .15, "logo").setScale(2);

		var electedBetAnimation = this.scene.tweens.add({
			targets: this.cadburyIcon,
			scale: 1.7,
			duration: 1000,
			yoyo: true,
			repeat: -1,
			ease: "Linear",
			onComplete: function () { 
		
			}
		  });


		this.addProgressBar();

		this.introSound = this.scene.sound.add("commercialBreakSFX", { loop: false });
		this.introSound.play();


		this.levelUpTransition();

	}

    addProgressBar() {
		var yPos = .9;
		var _self = this;
		this.current_level = 1
		this.scene.gameServer.getLifeLineData();
		console.log("addProgressBar",this.current_level)

		for(let i=0;i<6;i++) {

		var progressBar = this.scene.add.image(this.scene.cameras.main.width * .52,this.scene.cameras.main.height * yPos, "betbutton").setScale(.6).setInteractive();

		this.yPosition.push(this.scene.cameras.main.height * yPos);

		if(this.current_level -1 == i)
			progressBar.setAlpha(1);
		else {
			progressBar.setAlpha(.5);
			progressBar.disableInteractive();
		}

		this.progressBarLvl.add(progressBar)

		var progressBarText =  this.scene.add.text(this.scene.cameras.main.width * .52, this.scene.cameras.main.height * yPos, this.progressText[i]).setFontFamily("RussoOne").setColor("#FFFFFF").setFontSize(50).setOrigin(0.5).setDepth(2000);

		this.progressTextObj.push(progressBarText);

		_self.progressBarArr.push(progressBar)
		yPos = yPos - 0.1
		}

		this.addPoints();

		if(this.current_level <= 6)
			this.profileImg = this.scene.add.image(this.scene.cameras.main.width * .18,this.yPosition[this.current_level-1],"profileImg");

		this.progressBarLvl.getChildren().forEach(function(obj){
				obj.on('pointerdown', () => {
					_self.levelSelectionAnimation(obj,obj.y);
					_self.introSound.stop();
				})
		})
    }


    levelSelectionAnimation(selectedObject,currentY) {
        selectedObject.setAlpha(0);
		var selectedBet = this.scene.add.image(this.scene.cameras.main.width * .52,currentY, "betselected").setScale(.6).setDepth(1500)

		var _self =  this;

		var electedBetAnimation = this.scene.tweens.add({
			targets: selectedBet,
			scale: 0.7,
			duration: 500,
			yoyo: true,
			ease: "Linear",
			onComplete: function () { 
			selectedBet.destroy();
			_self.destroyProgressPanel();
			_self.cadburyIcon.destroy();
			_self.emptyBg.setPosition(_self.scene.cameras.main.width * .53,_self.scene.cameras.main.height * .5)
			_self.instantiateNewQuizModule();
			}
		  });
    }


    instantiateNewQuizModule() {
		var emitter = EventsManager.getInstance();
		emitter.emit('gameStart');
    }


    destroyProgressPanel(){
		this.scoreText.destroy();
		this.coins.destroy();
        for(let i=0;i<6;i++) {
			this.progressBarArr[i].destroy();
			this.progressTextObj[i].destroy();
		}
		this.progressBarArr.length = 0;
		this.progressTextObj.length = 0;
		this.profileImg.destroy();
    }


    levelUpTransition() {

	 this.profileImg.setPosition(this.scene.cameras.main.width * .18,this.yPosition[this.current_level-2])
	 this.yPosProfileImg = this.yPosition[this.current_level-1];

		var dialogBGtween = this.scene.tweens.add({
			targets: this.profileImg,
			y: this.yPosProfileImg,
			duration: 400,
			ease: "Ease.BounceIn",
			onComplete: function () {
			}
		  });
	}


}

