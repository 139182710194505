import EventsManager from "../utilities/EventsManager";

export default class Timer {

    scene : any;
    timerImg !: Phaser.GameObjects.Image;

	timerScaleDown : any;
	currentPointer : any;
	gameState !: any;

	gameStartTimer !: any;
	currentSeconds : number;

	alertAnimation !: any;
	emitter : any;

	godRayArr !: Array<any>;
	timer !: any;

	rectangle !: any;

	timerText !: any;

	timerCount !: number;

	gameplaySfx !: any;

    constructor(scene,x,y) {

     this.scene = scene;   
	 this.currentPointer = this;
	 this.godRayArr = []
	 this.emitter = EventsManager.getInstance();
	 this.currentSeconds = 0;
	 this.timerCount = 20;
	 this.handleEvents();

    }

	handleEvents() {
		this.emitter.on("gameStart",this.addProgressTimer.bind(this))
		this.emitter.on("gameStart",this.addTimerIcon.bind(this))
		this.emitter.on("gameStart",this.startTimer.bind(this))


		this.emitter.on("pauseTimer",this.pauseTimer.bind(this))
		this.emitter.on("resumeTimer",this.resumeTimer.bind(this))


		this.emitter.on("nextQuestion",this.destroyObjects.bind(this));

		this.emitter.on("Reload",this.destroyObjects.bind(this));

	}

    addProgressTimer() {
        
		this.rectangle = this.scene.add.rectangle(this.scene.cameras.main.width * .5, this.scene.cameras.main.height * 1, this.scene.cameras.main.width, 1280, 0x3a0243).setOrigin(0.5,1).setAlpha(0.5);
		this.rectangle.setStrokeStyle(2, 0xffffff);

		this.timerScaleDown = this.scene.tweens.add({
			targets: this.rectangle,
			scaleY: 0,
			duration: 20000,
			ease: "Linear",
			onComplete: function () { 
			}
		  });

		  this.gameplaySfx = this.scene.sound.add("gameplaySfx", { loop: false });
		  this.gameplaySfx.play();

    }

    addTimerIcon() {
        var godRayL = this.scene.add.image(this.scene.cameras.main.width * .52,this.scene.cameras.main.height * .12,"godray").setScale(.5).setTint(0x15f4ee)
		var godRayR = this.scene.add.image(this.scene.cameras.main.width * .52,this.scene.cameras.main.height * .12,"godray").setScale(.5).setTint(0x15f4ee)

		this.godRayArr.push(godRayL);
		this.godRayArr.push(godRayR);

		var dialogBGtween = this.scene.tweens.add({
			targets: godRayL,
			angle: 360,
			duration: 100000,
			yoyo: true,
			repeat: -1,
			ease: "Linear",
			onComplete: function () {
			}
		  });


		  var dialogBGtween = this.scene.tweens.add({
			targets: godRayR,
			angle: -360,
			duration: 100000,
			yoyo: true,
			repeat: -1,
			ease: "Linear",
			onComplete: function () {
			}
		  });


		this.timerImg = this.scene.add.image(this.scene.cameras.main.width * .52,this.scene.cameras.main.height * .12,"timerImg").setScale(1.2);
		this.godRayArr.push(this.timerImg);
		this.addTimerText();
    }

	pauseTimer() {
		this.timerScaleDown.pause();
		this.gameState = "pause";
	}

	resumeTimer() {
		this.timerScaleDown.resume();
		this.gameState = "run"
	}

    addTimerText() {
		this.timerText =  this.scene.add.text(this.scene.cameras.main.width * .52, this.scene.cameras.main.height * .12, this.timerCount).setFontFamily("RussoOne").setColor("#120058").setFontSize(110).setOrigin(0.5)
    }

	startTimer() {
		var warningAnimationTriggered = false;
		this.gameState = "run";
		var timeUpPanelTriggered = false;
		this.timer = this.scene.time.addEvent({
			delay: 1000,                
			callback: this.handleTimerLogic,
			Args : [timeUpPanelTriggered,warningAnimationTriggered],
			callbackScope: this,
			loop: true
		});
	}

	handleTimerLogic(timeUpPanelTriggered,warningAnimationTriggered) {
		var _self = this;
		        if(_self.gameState == "run"){
					_self.currentSeconds++;
					_self.timerCount--;
					if(_self.currentSeconds == 20) {
						_self.gameState = "timeUp";
					} else if(_self.currentSeconds > 15 && _self.currentSeconds < 20) {
						if(!warningAnimationTriggered) {
							_self.playAlertPanel()
							warningAnimationTriggered = true;
						}
					}
					_self.runTimer(_self.timerCount);
				} else if(_self.gameState == "timeUp") {
					_self.gameState = "gameOver"
					if(!timeUpPanelTriggered) {
						_self.triggerTimeUpPanel();
						_self.alertAnimation.destroy();
						timeUpPanelTriggered = true;
		}
	}
	}

	runTimer(currentSeconds) {
		this.timerText.setText(currentSeconds);
	}

	triggerTimeUpPanel() {
		this.alertAnimation.setAlpha(0);
		this.emitter.emit("nextQuestion")
		this.emitter.emit("timesUp");
	}

	destroyObjects() {
		for(let i=0;i<this.godRayArr.length;i++) {
		 this.godRayArr[i].destroy();
		}
		this.gameStartTimer = null;
		this.currentSeconds = 0;
		this.timerCount = 20
		this.rectangle.destroy()
		this.timerScaleDown.stop()
		this.timer.remove();
		this.timerText.destroy();

		this.scene.time.removeEvent(this.timer);
		this.gameState = "gameOver"

		this.gameplaySfx.stop();

	}

	playAlertPanel() {

		this.alertAnimation = this.scene.add.sprite(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5, "alertanimation01").setScale(0.9,1.1);
		this.scene.anims.create({
		  key:"alertAnimation1",
		  frames: this.scene.anims.generateFrameNumbers('alertAnimation', { start: 0, end: 3 }),
		  frameRate: 7
		});
		this.alertAnimation.play("alertAnimation1");

	}




    

}

