import EventsManager from "../utilities/EventsManager"


export default class Question  extends Phaser.GameObjects.Container {


  questionPanel !: Phaser.GameObjects.Image;
  optionsArr !: Array<any>

  scene: any;

  emitterHandler !: any;
  currentPointer !: any;

  emitter : any;

  questionText !: any;
  optionsTxt !: any;

  optionBox !: any;

  answerIndex !: number;

  groupOptionBtns !: any;

  textGrp !: any;

  questionTxtObj !: any;
  rightAnswerBtn !: any;
  wrongAnswerBtn !: any;

  sucessTextObj !: any;

  successBg !: any;
  rightLight !: any;
  leftLight !: any;

  rightChair !: any;
  leftChair !: any;

  nextLevelBtnText !: any;

  yahObj !: any;

  emitterCoin !: any;

  selectedOption !: any;

  optionTxtObjArr !: any;

  wrongSfx !: any;
  rightSfx !: any;

  current_level !: any;

  totalScore !: number;


  constructor(scene,x,y) {
    super(scene,x,y);
    this.scene = scene;
    this.rightAnswerBtn = null;
    this.wrongAnswerBtn = null;
    this.optionsArr = [];
    this.optionTxtObjArr = [];


    this.emitter = EventsManager.getInstance();
    this.emitter.on("gameStart",this.loadQuestionPanel.bind(this))
    this.emitter.on("updateQuestion",this.updateQuestion.bind(this))
    this.emitter.on("retry",this.destroyQAElements.bind(this))

    this.emitter.on("nextQuestion",this.destroyQAElements.bind(this));

    this.emitter.on("Reload",this.destroyQAElements.bind(this));

    this.emitter.on("timesUp",this.addTimesUpPopup.bind(this));


    //this.current_level = this.scene.gameServer.returnCurrentLevel();
    this.current_level = 1;

  }

  loadQuestionPanel() {
    this.textGrp = this.scene.add.group();
    this.totalScore = 300;
		this.questionPanel = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .3,"questionPanel").setAlpha(.5);
    this.current_level = 1;
    this.addQuestion();
    this.addOptions();
    this.getRightIndex();
	}

  destroyQAElements() {
  
    this.groupOptionBtns.clear(true);
    for(let i = 0;i<this.optionsArr.length;i++) {
      this.optionsArr[i].disableInteractive();
    }
    this.optionsArr.length = 0;

    for(let i = 0;i< this.optionTxtObjArr.length;i++) {
      this.optionTxtObjArr[i].disableInteractive();
    }
    this.optionTxtObjArr.length = 0;

    this.questionPanel.destroy();
    this.destroyTextElements();

    if(this.wrongAnswerBtn != null)
        this.wrongAnswerBtn.destroy();
    
    if(this.rightAnswerBtn != null) 
        this.rightAnswerBtn.destroy();

  }

  destroyTextElements() {
    this.textGrp.clear(true);
  }

  addQuestion() {
    this.questionTxtObj = this.scene.add.text(
                  this.scene.cameras.main.width * .5, 
                  this.scene.cameras.main.height * .3, 
                  this.getQuestionText())
                  .setFontFamily("RussoOne")
                  .setColor("#FFFFFF")
                  .setFontSize(60)
                  .setOrigin(0.5)
                  .setWordWrapWidth(800);

      this.textGrp.add(this.questionTxtObj);

      this.getAnswerIndex();

  }

  addOptionsText(index,yPos) {
   var optionText =   this.scene.add.text(
      this.scene.cameras.main.width * .52, 
      this.scene.cameras.main.height * yPos, 
      this.getOptions(index))
      .setFontFamily("RussoOne")
      .setColor("#FFFFFF")
      .setFontSize(40)
      .setOrigin(0.5)
      .setWordWrapWidth(400)
      .setDepth(2005);
      this.textGrp.add(optionText);

    return optionText;

  }

  getOptions(index) {
    console.log("this.current_level",this.current_level)
    this.optionsTxt = this.scene.data.categories[0].questions[this.current_level-1].choices[index];
    return this.optionsTxt;
  }

  getQuestionText() {
    this.questionText = this.scene.data.categories[0].questions[this.current_level-1].question;
    return this.questionText;
  }

  getAnswerIndex() {
    this.scene.rightChoice =  this.scene.data.categories[0].questions[this.current_level-1].answer;
  }

  updateQuestion() {
    this.questionTxtObj.setText(this.scene.data.categories[0].questions[5].question);

    for(let i=0;i<this.optionTxtObjArr.length;i++){
      this.optionTxtObjArr[i].setText(this.scene.data.categories[0].questions[5].choices[i])
      
    }
  }

  getRightIndex() {
    this.answerIndex = this.scene.data.categories[0].questions[this.current_level-1].answer;
  }

  addOptions() {
    this.groupOptionBtns = this.scene.add.group();
    var yPos = .47;
		for(let i=0;i<4;i++) {
		  this.optionBox = this.scene.add.image(this.scene.cameras.main.width * .52,this.scene.cameras.main.height * yPos, "progressButton").setScale(.6).setInteractive();
      this.optionBox.index = i;
      var optionTxtObj = this.addOptionsText(i,yPos);
      this.groupOptionBtns.add(this.optionBox);
      this.optionTxtObjArr.push(optionTxtObj)
      this.optionsArr.push(this.optionBox);
			yPos = yPos + .1
		}

    var _self = this;

    this.groupOptionBtns.getChildren().forEach(function(obj){
       obj.on('pointerdown', () => {
         obj.disableInteractive();
         if(obj.index == _self.answerIndex) {
          _self.rightSfx = _self.scene.sound.add("correctAnswerSFX", { loop: false });
          _self.rightSfx.play();
           var self = _self;
           _self.selectedOption = true;
           _self.rightAnswerBtn =  _self.scene.add.image(_self.scene.cameras.main.width * .52,obj.y,"rightAnswer").setScale(.7).setDepth(2001);
            var dialogBGtween =  _self.scene.tweens.add({
              targets: _self.rightAnswerBtn,
              scale: .8,
              duration: 500,
              yoyo: true,
              ease: "Linear",
              onComplete: function () {
                _self.emitter.emit("nextQuestion")
                console.log("_self.scene.currentLevel",_self.current_level+1,_self.current_level)
               _self.scene.gameServer.updateScore(100,_self.current_level);
                _self.addSucessPopup();
              }
          });
        } else {
          _self.selectedOption = false;
          _self.wrongSfx = _self.scene.sound.add("wrongAnswerSFX", { loop: false });
          _self.wrongSfx.play();
          _self.wrongAnswerBtn =  _self.scene.add.image(_self.scene.cameras.main.width * .52,obj.y,"wrongAnswer").setScale(.8).setDepth(2001);
          var dialogBGtween =  _self.scene.tweens.add({
            targets: _self.wrongAnswerBtn,
            scale: .8,
            duration: 1000,
            ease: "Linear",
            onComplete: function () {
               _self.emitter.emit("nextQuestion")
              _self.addSucessPopup();
            }
        });
        }
    });
  })
  }

  addSucessPopup() {
    this.successBg = this.scene.add.image(this.scene.cameras.main.width * .53 ,this.scene.cameras.main.height * .55,"portBg").setScale(1.2);
		this.animateSpotLight();
  }

  addTimesUpPopup() {
    this.successBg = this.scene.add.image(this.scene.cameras.main.width * .53 ,this.scene.cameras.main.height * .55,"portBg").setScale(1.2);
    this.timeupSpotLight();
  }

  timeupSpotLight() {
    this.leftLight = this.scene.add.image(this.scene.cameras.main.width * .7,-this.scene.cameras.main.height *.08,"spotLight").setAngle(20).setOrigin(0.5,0).setTint(0xFF0000);
		this.rightLight = this.scene.add.image(this.scene.cameras.main.width * .29,-this.scene.cameras.main.height * .08,"spotLight").setAngle(-20).setOrigin(0.5,0).setTint(0xFF0000);;

    this.leftChair = this.scene.add.image(this.scene.cameras.main.width * .3,this.scene.cameras.main.height * .72,"chair").setScale(.3);

    this.rightChair = this.scene.add.image(this.scene.cameras.main.width * .7,this.scene.cameras.main.height * .72,"chair").setScale(.3);
    this.rightChair.flipX = true;


    this.addtimeUpText();
    var confirmBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .57, "wrongAnswer").setScale(0.6).setInteractive().on('pointerdown', () => {
      this.sucessTextObj.destroy();
      confirmBtn.destroy();
      this.successBg.destroy();
      this.rightChair.destroy();
      this.yahObj.destroy();
      this.leftChair.destroy();
      this.leftLight.destroy();
      this.rightLight.destroy();
      this.nextLevelBtnText.destroy();
      this.emitter.emit("reload")
    });
    this.confirmBtnHover(confirmBtn);
  }


  animateSpotLight() {

    this.leftLight = this.scene.add.image(this.scene.cameras.main.width * .7,-this.scene.cameras.main.height *.08,"spotLight").setAngle(20).setOrigin(0.5,0);
		this.rightLight = this.scene.add.image(this.scene.cameras.main.width * .29,-this.scene.cameras.main.height * .08,"spotLight").setAngle(-20).setOrigin(0.5,0)

    this.leftChair = this.scene.add.image(this.scene.cameras.main.width * .3,this.scene.cameras.main.height * .72,"chair").setScale(.3);

    this.rightChair = this.scene.add.image(this.scene.cameras.main.width * .7,this.scene.cameras.main.height * .72,"chair").setScale(.3);
    this.rightChair.flipX = true;

		var _self = this;
		var dialogBGtween = this.scene.tweens.add({
			targets: this.leftLight,
			angle: -60,
			duration: 600,
			yoyo: true,
			repeat: 1,
			ease: "Linear",
			onComplete: function () {
        _self.SpotLightOverAnimation();
			}
		  });
		  var dialogBGtween = this.scene.tweens.add({
			targets: this.rightLight,
			angle: 60,
			duration: 600,
			yoyo: true,
			repeat: 1,
			ease: "Linear",
			onComplete: function () {
      
			}
		  });
  }

  gameEndedText() {
    this.sucessTextObj = this.scene.add.text(
      this.scene.cameras.main.width * .5, 
      this.scene.cameras.main.height * .45, 
      "You've won $100!")
      .setFontFamily("RussoOne")
      .setColor("#D4AF37")
      .setFontSize(70)
      .setOrigin(0.5)
      .setWordWrapWidth(900);

    this.yahObj = this.scene.add.text(
        this.scene.cameras.main.width * .5, 
        this.scene.cameras.main.height * .37, 
        "Congrats!")
        .setFontFamily("RussoOne")
        .setColor("#FFFFFF")
        .setFontSize(80)
        .setOrigin(0.5)
        .setWordWrapWidth(800);

    this.playEmitterEffect();
  }

  SpotLightOverAnimation() {

    if(this.selectedOption == true) {
      this.addScoreBar();
    this.rightLight.setTint(0x00FF00);
    this.leftLight.setTint(0x00FF00);
    this.current_level == 6 ? this.gameEndedText() : this.addConfirmPopupText();
    var confirmBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .57, "rightAnswer").setScale(0.6).setInteractive().on('pointerdown', () => {
      this.sucessTextObj.destroy();
      confirmBtn.destroy();
      this.successBg.destroy();
      this.rightChair.destroy();
      this.yahObj.destroy();
      this.leftChair.destroy();
      this.leftLight.destroy();
      this.rightLight.destroy();
      this.nextLevelBtnText.destroy();
      this.scene.questionIndex++;
      if(this.current_level == 6) {
        localStorage.clear();
        location.reload();
      } else  {
        this.emitter.emit("loadNextLevel");
      } 
  });
  this.confirmBtnHover(confirmBtn);
    } else  {
      this.rightLight.setTint(0xFF0000);
      this.leftLight.setTint(0xFF0000);
      this.addFailedLevelText();
      var confirmBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .57, "wrongAnswer").setScale(0.6).setInteractive().on('pointerdown', () => {
        this.sucessTextObj.destroy();
        confirmBtn.destroy();
        this.successBg.destroy();
        this.rightChair.destroy();
        this.yahObj.destroy();
        this.leftChair.destroy();
        this.leftLight.destroy();
        this.rightLight.destroy();
        this.nextLevelBtnText.destroy();
        this.emitter.emit("reload")
      });
      this.confirmBtnHover(confirmBtn);
    }

  }

  confirmBtnHover(confirmBtn) {
    var text;
    var tweenPlayBtn = this.scene.tweens.add({
      targets:confirmBtn,
      scale: 0.7,
      yoyo: true,
      repeat: -1,
      ease: "power"
  });
  
    if(this.selectedOption == true) {
      if(this.current_level != 6) {
        text =  "NEXT";
      } else {
        text =  "HOME";
      }
     } else  
        text = "RETRY";
 

  this.nextLevelBtnText = this.scene.add.text(
    confirmBtn.x, 
    confirmBtn.y, 
    text)
    .setFontFamily("RussoOne")
    .setColor("#FFFFFF")
    .setFontSize(50)
    .setOrigin(0.5)
    .setWordWrapWidth(400)
    .setDepth(2005);
  }

  playEmitterEffect() {

   // var coinImg = this.scene.add.sprite(this.scene.cameras.main.width * .7,this.scene.cameras.main.height * .12, "coin").setAngle(90).setScale(.5).setDepth(2500);
    let particleCoin = this.scene.add.particles('coin').setDepth(2500)

    this.emitterCoin = particleCoin.createEmitter({
        x: this.scene.cameras.main.width * .5,
        y:  -this.scene.cameras.main.height * .15,
        quantity: {min:2, max: 5},
        frequency: 10,
        angle: { min: 180, max: 360 },
        rotate: { min: 0, max: 300},
        speed: 1000,
        gravityY:2000,
        scale: { min : 0.2, max: 0.3},
        lifespan: 5000,
        particleClass: Phaser.GameObjects.Particles.Particle
    });

    var _self = this;
    this.scene.time.delayedCall(4000, ()=>{
      _self.emitterCoin.stop();
      _self.scene.gameServer.sendScoreToApp(300,this.scene.tokenId,this.scene.program_id);
   });
  }


  addConfirmPopupText() {
    this.sucessTextObj = this.scene.add.text(
      this.scene.cameras.main.width * .5, 
      this.scene.cameras.main.height * .45, 
      "You've got it right!")
      .setFontFamily("RussoOne")
      .setColor("#FFFFFF")
      .setFontSize(80)
      .setOrigin(0.5)
      .setWordWrapWidth(800);

    this.yahObj = this.scene.add.text(
        this.scene.cameras.main.width * .5, 
        this.scene.cameras.main.height * .4, 
        "yah!")
        .setFontFamily("RussoOne")
        .setColor("#FFFFFF")
        .setFontSize(80)
        .setOrigin(0.5)
        .setWordWrapWidth(800);

  }


  addFailedLevelText() {
    this.sucessTextObj = this.scene.add.text(
      this.scene.cameras.main.width * .5, 
      this.scene.cameras.main.height * .45, 
      "Thats wrong!")
      .setFontFamily("RussoOne")
      .setColor("#FFFFFF")
      .setFontSize(80)
      .setOrigin(0.5)
      .setWordWrapWidth(900);

    this.yahObj = this.scene.add.text(
        this.scene.cameras.main.width * .5, 
        this.scene.cameras.main.height * .4, 
        "Oops!")
        .setFontFamily("RussoOne")
        .setColor("#FFFFFF")
        .setFontSize(80)
        .setOrigin(0.5)
        .setWordWrapWidth(800);
  }


  addtimeUpText() {
    this.sucessTextObj = this.scene.add.text(
      this.scene.cameras.main.width * .5, 
      this.scene.cameras.main.height * .45, 
      "Times Up!")
      .setFontFamily("RussoOne")
      .setColor("#FFFFFF")
      .setFontSize(80)
      .setOrigin(0.5)
      .setWordWrapWidth(900);

    this.yahObj = this.scene.add.text(
        this.scene.cameras.main.width * .5, 
        this.scene.cameras.main.height * .4, 
        "Oops!")
        .setFontFamily("RussoOne")
        .setColor("#FFFFFF")
        .setFontSize(80)
        .setOrigin(0.5)
        .setWordWrapWidth(800);
  }


  returnOptionsArr() {
    return this.optionsArr;
  }

  returnQuestionArr() {
    return this.questionPanel;
  }


  addScoreBar() {
   
  }





}

