import EventsManager from "../utilities/EventsManager"

export default  class LifeLine extends Phaser.GameObjects.Container {

	salesPertIcon  !: Phaser.GameObjects.Image;
	swapIcon !: Phaser.GameObjects.Image;
	fiftyIcon !: Phaser.GameObjects.Image;

    fiftyFifty !: Phaser.GameObjects.Image;
    askSalesExpert !: Phaser.GameObjects.Image;
    flipTheQuestion !: Phaser.GameObjects.Image;

	btnsArr !: Array<any>;

    scene: any;

	emitter : any;
	questionPanel !: any;

	confirmText !: any;

	lifelinepopupText !: any;

	optionsArr : Array<any>;

	hintText !: any;

	optionTxtObjArr !: Array<any>;

	currentLevel !: any;

    constructor(scene,x,y) {
        super(scene,x,y);
		this.btnsArr = [];
		this.optionsArr = [];
		this.emitter = EventsManager.getInstance();

		this.emitter.on("gameStart",this.addLifeLines.bind(this))

		this.emitter.on("retry",this.destroyLifelineObj.bind(this));

		this.emitter.on("nextQuestion",this.destroyObjForNextQuestion.bind(this));

		this.emitter.on("Reload",this.destroyObjForNextQuestion.bind(this))

    }


	destroyLifelineObj() {
		for(let i=0;i<this.btnsArr.length;i++) {
			this.btnsArr[i].disableInteractive();
			this.btnsArr[i].destroy();
		}

		for(let i=0;i<this.optionsArr.length;i++) {
			this.optionsArr[i].disableInteractive();
			this.optionsArr[i].destroy();
		}
		
		this.btnsArr.length = 0;
		this.optionsArr.length = 0;
	}


	destroyObjForNextQuestion() {
		for(let i=0;i<this.btnsArr.length;i++) {
			this.btnsArr[i].disableInteractive();
			this.btnsArr[i].destroy();
		}

		this.btnsArr.length = 0;

		for(let i=0;i<this.optionsArr.length;i++) {
			this.optionsArr[i].disableInteractive();
			this.optionsArr[i].destroy();
		}

		this.optionsArr.length = 0;

	}

    
	addLifeLines() {

		this.currentLevel = 1

		console.log("this.currentLevel",this.currentLevel                                                                                                                     )
		
		// var is5050Available = this.scene.gameServer.return5050LifeLineStatus();
		// var isSwapAvailable = this.scene.gameServer.returnSwapLifeLineStatus();
		// var isAskAvailable  = this.scene.gameServer.returnAskLifeLineStatus();

		var is5050Available = 1
		var isSwapAvailable = 1
		var isAskAvailable  = 1


		console.log("is5050Available",is5050Available,isSwapAvailable,isAskAvailable);

		var _self = this;

		_self.fiftyFifty= this.scene.add.image(this.scene.cameras.main.width * .21,this.scene.cameras.main.height * .92, "buttonIcon").setScale(1.2).setInteractive().on('pointerdown', () => {
			_self.buttonScaleUpAnimation(1,_self.fiftyFifty);
		});

		_self.btnsArr.push(_self.fiftyFifty)

		if(is5050Available == 0) {
			_self.fiftyFifty.setAlpha(0.5);
			_self.fiftyFifty.disableInteractive();
		} 

		_self.askSalesExpert = this.scene.add.image(this.scene.cameras.main.width * .51,this.scene.cameras.main.height * .92, "buttonIcon").setScale(1.2).setInteractive().on('pointerdown' , () => {
            _self.buttonScaleUpAnimation(2,_self.askSalesExpert);
		});

		_self.btnsArr.push(_self.askSalesExpert)

		if(isAskAvailable == 0) {
			_self.askSalesExpert.setAlpha(0.5);
			_self.askSalesExpert.disableInteractive();
		}

		_self.flipTheQuestion = this.scene.add.image(this.scene.cameras.main.width * .81,this.scene.cameras.main.height * .92, "buttonIcon").setScale(1.2).setInteractive().on('pointerdown', () => {
            _self.buttonScaleUpAnimation(3,_self.flipTheQuestion);
		});

		_self.btnsArr.push(_self.flipTheQuestion)

		if(isSwapAvailable == 0) {
			_self.flipTheQuestion.setAlpha(0.5);
			_self.flipTheQuestion.disableInteractive();
		}

		_self.salesPertIcon = this.scene.add.image(this.scene.cameras.main.width * .51,this.scene.cameras.main.height * .92,"asksalespert").setScale(1.3);
		_self.btnsArr.push(_self.salesPertIcon)

		_self.swapIcon = this.scene.add.image(this.scene.cameras.main.width * .81,this.scene.cameras.main.height * .92,"swapIcon").setScale(1.3);
		_self.btnsArr.push(_self.swapIcon)

		_self.fiftyIcon = this.scene.add.image(this.scene.cameras.main.width * .21,this.scene.cameras.main.height * .92,"fiftyIcon").setScale(1.3);
		_self.btnsArr.push(_self.fiftyIcon)


	}


    buttonScaleUpAnimation(index,obj) {
        var _self = this;
        var dialogBGtween = this.scene.tweens.add({
            targets: obj,
            scale: 1.5,
            yoyo: true,
            duration: 200,
            ease: "Sine.easeInOut",
            onComplete: function () {
                _self.popUpConfirmationRectangle(index);
            }
          });
    }
	//Lifeline 5050

	popUpConfirmationRectangle(lifelineIndex : number) {
        var _self = this;
		let greyScale = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5,"greyScale").setAlpha(0.5).setScale(10)
		let popupRect = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5,"popupPanel").setScale(0).setDepth(3000)
        var dialogBGtween = this.scene.tweens.add({
            targets: popupRect,
            scaleY: 1.2,
            scaleX: 0.9,
            duration: 200,
            ease: "Sine.easeInOut",
            onComplete: function () {
                _self.loadLifeLineData(popupRect,greyScale,lifelineIndex)
            }
          });
	}

    loadLifeLineData(popupRect,greyScale,lifelineIndex) {
		this.getOptionsArr();
		this.getQuestionsArr();
        var _self = this;
		var businessMan, confirmBtn;
        var closeBtn = this.scene.add.image(this.scene.cameras.main.width * .93,this.scene.cameras.main.height * .38,"close").setDepth(3005).setInteractive().on('pointerdown', () => {
			greyScale.destroy();
			popupRect.destroy();
			businessMan.destroy();
			closeBtn.destroy();
			confirmBtn.destroy();
            _self.emitter.emit('resumeTimer');
			_self.lifelinepopupText.destroy();
			_self.confirmText.destroy();
		 	// this.playAlertPanel(self);
		});

        _self.emitter.emit('pauseTimer');
		var dialogBGtween = this.scene.tweens.add({
			targets: popupRect,
			height: 600,
			duration: 100,
			ease: "Linear",
			onComplete: function () {
				businessMan = _self.scene.add.image(_self.scene.cameras.main.width * .22,_self.scene.cameras.main.height * .48, "businessman").setScale(1.5,1.2).setDepth(3005)
				confirmBtn = _self.scene.add.image(_self.scene.cameras.main.width * .6,_self.scene.cameras.main.height * .57, "progressButton").setScale(.4).setDepth(3005).setInteractive().on('pointerdown', () => {
					greyScale.destroy();
					popupRect.destroy();
					businessMan.destroy();
					confirmBtn.destroy();
					_self.lifelinepopupText.destroy();
					_self.confirmText.destroy();
					closeBtn.destroy();
					switch(lifelineIndex) {
						case 1:
						_self.play5050Transition();
						_self.emitter.emit('resumeTimer',_self);
						_self.fiftyFifty.disableInteractive().setAlpha(.5)
						_self.scene.gameServer.updateLifeline(1,_self.currentLevel)
					break;
						case 2: 
						_self.playAskSalesExpert();
						_self.askSalesExpert.disableInteractive().setAlpha(.5);
					break;
						case 3:
						_self.playFlipTheQuestion();
						_self.emitter.emit('resumeTimer',_self);
						_self.flipTheQuestion.disableInteractive().setAlpha(.5);
						_self.scene.gameServer.updateLifeline(3,_self.currentLevel)
					break;
						default:
					break;
					}
				});

				_self.confirmText = _self.scene.add.text(
					_self.scene.cameras.main.width * .6, 
					_self.scene.cameras.main.height * .57, 
					"Confirm")
					.setFontFamily("RussoOne")
					.setColor("#FFFFFF")
					.setFontSize(50)
					.setOrigin(0.5)
					.setDepth(3006)
					.setWordWrapWidth(600);


				switch(lifelineIndex) {
					case 1:
						_self.lifelinepopupText = _self.scene.add.text(
							_self.scene.cameras.main.width * .6, 
							_self.scene.cameras.main.height * .47, 
							"Are you sure you want to use 50:50 lifeline?")
							.setFontFamily("RussoOne")
							.setColor("#FFFFFF")
							.setFontSize(50)
							.setOrigin(0.5)
							.setDepth(3006)
							.setWordWrapWidth(650);
					
				break;
					case 2: 
					_self.lifelinepopupText = _self.scene.add.text(
						_self.scene.cameras.main.width * .6, 
						_self.scene.cameras.main.height * .47, 
						"Are you sure you want to use Ask a sales expert lifeline?")
						.setFontFamily("RussoOne")
						.setColor("#FFFFFF")
						.setFontSize(50)
						.setOrigin(0.5)
						.setDepth(3006)
						.setWordWrapWidth(650);
				
				break;
					case 3:
						_self.lifelinepopupText = _self.scene.add.text(
							_self.scene.cameras.main.width * .6, 
							_self.scene.cameras.main.height * .47, 
							"Are you sure you want to use flip the question lifeline?")
							.setFontFamily("RussoOne")
							.setColor("#FFFFFF")
							.setFontSize(50)
							.setOrigin(0.5)
							.setDepth(3006)
							.setWordWrapWidth(700);
				
				break;
					default:
				break;
				}

			}
		  });
    }


    getOptionsArr() {
	 this.optionsArr =	this.scene.QuestionObj.returnOptionsArr();
    }

	getQuestionsArr() {
		this.questionPanel = this.scene.QuestionObj.returnQuestionArr();
	}

	play5050Transition() {
		var choiceArr = [0,1,2,3];
		choiceArr.splice(this.scene.rightChoice,1);

		var randomPos1 = Math.floor(Math.random()*choiceArr.length)
		var randomVal1 = choiceArr[randomPos1];
		choiceArr.splice(randomPos1,1);

		var randomPos2 = Math.floor(Math.random()*choiceArr.length)
		var randomVal2 = choiceArr[randomPos2];
		choiceArr.splice(randomPos2,1);

			for(let i=0;i<this.optionsArr.length;i++) {
				if(i == randomVal1 || i == randomVal2) {
					this.optionsArr[i].disableInteractive();
				var dialogBGtween = this.scene.tweens.add({
					targets: this.optionsArr[i],
					alpha: 0.15,
					duration: 500,
					ease: "Linear",
					onComplete: function () {
					}
		  });
				}
			}
 	}


	playAskSalesExpert() {
         var _self = this;
		var businessMan, confirmBtn;
		let greyScale = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5,"greyScale").setAlpha(0.5).setScale(10)
		let popupRect = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5,"popupPanel").setScale(0.9,1.2).setDepth(3005)
		var closeBtn = this.scene.add.image(this.scene.cameras.main.width * .93,this.scene.cameras.main.height * .38,"close").setDepth(3005).setInteractive().on('pointerdown', () => {
			greyScale.destroy();
			popupRect.destroy();
			businessMan.destroy();
			_self.hintText.destroy();
			closeBtn.destroy();
			//Resume timer
            _self.emitter.emit('resumeTimer');
			_self.scene.gameServer.updateLifeline(2,_self.currentLevel)

		});
		var dialogBGtween = this.scene.tweens.add({
			targets: popupRect,
			height: 600,
			duration: 300,
			ease: "Linear",
			onComplete: function() {
				businessMan = _self.scene.add.image(_self.scene.cameras.main.width * .22,_self.scene.cameras.main.height * .48, "businessman").setScale(1.5).setDepth(3005);
				_self.hintText = _self.scene.add.text(
					_self.scene.cameras.main.width * .6, 
					_self.scene.cameras.main.height * .47, 
					_self.scene.data.categories[0].questions[_self.scene.questionIndex].hint)
					.setFontFamily("RussoOne")
					.setColor("#FFFFFF")
					.setFontSize(50)
					.setOrigin(0.5)
					.setDepth(3006)
					.setWordWrapWidth(700);
			}
	 })
	}


	playFlipTheQuestion(){
		 var _self = this;
		for(let i=0;i<this.optionsArr.length;i++) {
			this.optionsArr[i].setInteractive().setAlpha(1);
			var dialogBGtween = this.scene.tweens.add({
				targets: this.optionsArr[i],
				scaleY: 0,
				duration: 200,
				yoyo: true,
				ease: "Linear",
				onComplete: function () {
                _self.emitter.emit("updateQuestion")
				}
			  });
		}	
		
		var dialogBGtween = this.scene.tweens.add({
			targets: this.questionPanel,
			scaleY: 0,
			duration: 200,
			yoyo: true,
			ease: "Linear",
			onComplete: function () {
			}
		  });
	}

	updateTheNewQA() {

	}



    
}
